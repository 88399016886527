import axios from 'axios';

const apiClient = axios.create({
    // baseURL: 'http://localhost:8000/api/v1.0',
    // baseURL: 'https://wonderpriceai-backend-5d6abadda4d5.herokuapp.com/api/v1.0',
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// 在请求发送前统一设置Authorization头部
apiClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

import * as queryByChat from './queryByChat';
import * as queryBySpec from './queryBySpec';
import * as confirmQuery from './confirmQuery';
import * as login from './login';
import * as uploadBom from './uploadBom';
import * as register from './register'

export {
    apiClient,
    queryByChat,
    queryBySpec,
    confirmQuery,
    login,
    uploadBom,
    register
};

